import React from "react";
import kklogo2 from "../kklogo2.png";
import "../App.css";

function Home() {
  return (
    <div className="App">
      <div className="App-header">
        <img src={kklogo2} className="App-logo" alt="logo" />
      </div>
    </div>
  );
}

export default Home;
